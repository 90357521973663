// Number thresholds
const SCALE = { K: 1_000, TEN_K: 10_000, M: 1_000_000, B: 1_000_000_000 };

// Format styles
export enum Style {
  COMPACT, // Uses K suffix for all values ≥ 1K (e.g., "1.2K")
  STANDARD, // Uses commas for values < 10K, then suffixes (e.g., "1,234", "10K")
}

// Options for formatting
type FormatOpts = {
  locale?: string;
  maxDecimals?: number;
  minDecimals?: number;
  style?: Style;
};

/**
 * Format numbers with appropriate suffixes (K, M, B)
 */
export function formatNumber(num: number, opts: FormatOpts = {}): string {
  // Parse options with defaults
  const { locale = 'en', maxDecimals = 1, minDecimals = 0, style = Style.STANDARD } = opts;

  // Create formatter
  const fmt = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });

  // Add suffix helper
  const withSuffix = (val: number, scale: number, suffix: string): string =>
    `${fmt.format(Math.floor((val / scale) * 10) / 10)}${suffix}`;

  // Format rules by style
  const rules = [
    { scale: SCALE.B, suffix: 'B' },
    { scale: SCALE.M, suffix: 'M' },
    { min: SCALE.TEN_K, scale: SCALE.K, suffix: 'K' },
    { scale: SCALE.K, suffix: 'K', applyWhen: style === Style.COMPACT },
  ];

  // Apply first matching rule
  for (const rule of rules) {
    const threshold = rule.min || rule.scale;
    if (num >= threshold && (rule.applyWhen === undefined || rule.applyWhen)) {
      return withSuffix(num, rule.scale, rule.suffix);
    }
  }

  // No rules matched
  return fmt.format(num);
}

// Helper to create a formatter with a specific style
const createFormatter = (style: Style) => {
  return (num: number, opts: Omit<FormatOpts, 'style'> = {}) =>
    formatNumber(num, { ...opts, style });
};

// Create specialized formatters
export const formatPoints = createFormatter(Style.STANDARD);
export const formatMiles = createFormatter(Style.COMPACT);

/**
 * Test cases:
 * Standard: formatNumber(999) → "999", formatNumber(1_234) → "1,234"
 * With style: formatNumber(1_234, {style: Style.COMPACT}) → "1.2K"
 * Convenience: formatPoints(1_234) → "1,234", formatMiles(1_234) → "1.2K"
 */
