import { memo, useContext, useEffect, useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import Footer from './Shell/Footer';
import Header from './Shell/Header';
import CoBrandBar from './Shell/CoBrandBar';

import ContractAgreement from './_components/ContractAgreement';
import ForceConsentModal from './_components/ForceConsentModal';
import PageLoader from '../_components/PageLoader';
import { Container } from '../_components/Layout';

import { useBraze } from '../_context/BrazeContext';
import { UserContext } from '../_context/UserContext';
import { useOrgProFeatures } from '../_context/OrgProFeaturesContext';
import { useConsents } from '../_context/ConsentsContext';

import { bearerToken } from '../_utils/authAxios';
import useIsMounted from '../_hooks/useIsMounted';

import { ROUTES } from '../_routes';

export const AuthLayout = ({ children }) => {
  const authToken = bearerToken();
  const braze = useBraze();
  const isMounted = useIsMounted();
  const { fetch, loaded, loading, userData } = useContext(UserContext);

  useEffect(() => {
    if (!userData && !loading) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (braze && userData?.id) {
      braze.changeUser(userData?.id);
    }
  }, [braze, userData?.id]);

  const { orgPaidFeatures, loading: orgprofeaturesLoading } = useOrgProFeatures();
  const { orgHasPaidDigitalFeature } = orgPaidFeatures || {};
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    isAdmin,
    isInstructor,
    isManager,
    isOwner,
    isUser,
    isSubuser,
    is_active: isActive,
    organization,
    partner_registration_status,
    partner_onboarding_status,
  } = userData;

  // Checks if user has a paid digital subscription
  // Checks if user has a paid BrainTree subscription
  const paidDigitalSub =
    userData?.new_subscription?.filter(
      sub => sub.plan_type === 'digital' || sub.plan_type === 'braintree',
    ).length > 0;

  // orgHasPaidDigitalFeature: Checks if org has any of the paid features
  // imported from _components/_utils

  // If org has paid features enabled and user doesn't have a paid digital subscription
  const userHasToPay = orgHasPaidDigitalFeature && !paidDigitalSub && !isSubuser;

  const isOwnerPartner = isOwner && !partner_registration_status;
  const isInstructorPartner = isInstructor && !partner_registration_status;
  const isPartnerOnboarding = (isOwner || isInstructor) && !partner_onboarding_status;

  const isPssOnly = useMemo(
    () => Boolean((isOwner || isInstructor || isManager) && !isUser && isActive),
    [isActive, isInstructor, isManager, isOwner, isUser],
  );
  const isAllowOnWell = useMemo(() => Boolean((isAdmin || isUser) && isActive), [
    isActive,
    isAdmin,
    isUser,
  ]);

  const { consents, initialLoading: consentsInitialLoading } = useConsents();
  const shareDataConsent = consents?.find(consent => consent?.name?.toLowerCase() === 'sharedata');

  // make sure page doesn't execute on components until everything is mounted
  if (!isMounted) return null;

  // if we don't have an auth token, the user is not logged in
  if (!authToken) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return loading || !loaded || consentsInitialLoading || orgprofeaturesLoading ? (
    <PageLoader height="100vh" />
  ) : isOwnerPartner || isInstructorPartner ? (
    <Navigate to={`${ROUTES.PARTNER}${ROUTES.NEW}`} />
  ) : isPartnerOnboarding ? (
    <Navigate to={ROUTES.ONBOARDING} />
  ) : userHasToPay ? (
    <Navigate to={ROUTES.SUBSCRIPTIONS_NEW} />
  ) : isPssOnly ? (
    <Navigate to={ROUTES.PSS} />
  ) : isAllowOnWell ? (
    shareDataConsent?.force ? (
      <ForceConsentModal consentToForce={shareDataConsent} />
    ) : (
      <>
        <Wrapper fluid $menuOpen={menuOpen}>
          <Header setMenuOpen={() => setMenuOpen()} />
          {organization?.show_banner && <CoBrandBar />}
          <ContractAgreement show={userData?.id && !userData?.partner_agreement_validated} />
          {children}
          <Footer />
        </Wrapper>
      </>
    )
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};

const Wrapper = styled(Container)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.neutralGrey};
  position: fixed;
  height: 100%;
  overflow-y: ${({ $menuOpen }) => ($menuOpen ? 'hidden' : 'auto')};
`;

export default memo(AuthLayout);
